import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/home",
    redirect: "/",
  },
  {
    path: "/projects",
    redirect: "/",
  },
  {
    path: "/projects/ethereal",
    name: "Ethereal",
    component: () =>
      import(
        /* webpackChunkName: "ethereal" */ "../views/projects/Ethereal.vue"
      ),
  },
  {
    path: "/projects/ethereal-deviations",
    name: "Ethereal Deviations",
    component: () =>
      import(
        /* webpackChunkName: "ethereal-deviations" */ "../views/projects/EtherealDeviations.vue"
      ),
  },
  {
    path: "/projects/central-vibrance",
    name: "CentralVibrance",
    component: () =>
      import(
        /* webpackChunkName: "central-vibrance" */ "../views/projects/CentralVibrance.vue"
      ),
  },
  {
    path: "/projects/density",
    name: "Density",
    component: () =>
      import(/* webpackChunkName: "density" */ "../views/projects/Density.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(savedPosition || { top: 0 });
      }, 500);
    });
  },
});

router.afterEach((to, from) => {
  to.meta.transitionName = "route";
  to.meta.lastPage = from.path;
});

export default router;
