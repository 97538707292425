<template>
  <a @click="close">
    <div class="back-button">
      <svg class="back-button__arrow" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 72">
        <path class="back-button__arrow-top" d="M0,32.1L0,32.1L0,32.1c0-4.2,1.7-8.3,4.6-11.2L20.9,4.6C23.8,1.7,28,0,32.1,0l0,0l0,0c0,4.2-1.7,8.3-4.6,11.2L11.2,27.6
	C8.3,30.5,4.2,32.1,0,32.1z" />
        <path class="back-button__arrow-middle" d="M10,36L10,36L10,36c3-3,7.1-4.7,11.2-4.7h75.6c4.2,0,8.2,1.7,11.2,4.7l0,0l0,0c-3,3-7.1,4.7-11.2,4.7H21.2
	C17.1,40.7,13.1,39,10,36z" />
        <path class="back-button__arrow-bottom" d="M0,39.9L0,39.9L0,39.9c4.2,0,8.3,1.7,11.2,4.6l16.3,16.3c2.9,2.9,4.6,7.1,4.6,11.2l0,0l0,0c-4.2,0-8.3-1.7-11.2-4.6
	L4.6,51.1C1.7,48.2,0,44,0,39.9z" />
      </svg>
    </div>
  </a>
</template>

<script>
import { useRouter } from "vue-router"
export default {
  name: "BackButton",
  setup() {
    const router = useRouter()
    return {
      close() {
        // Sends the page to the new website if there is no page history.
        router.options.history.state.back ? router.back() : window.location.href = 'https://generatively.xyz/projects'
      }
    }
  }
};
</script>

<style scoped>
.back-button {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 50px;
  margin: 25px;
  cursor: pointer;
  z-index: 100;
  mix-blend-mode: exclusion;
}

.back-button__arrow {
  fill: white;
  overflow: visible;
}

.back-button__arrow * {
  transition: transform 0.1s;
}

.back-button__arrow:active .back-button__arrow-top {
  transform: translate(12px, -12px);
}

.back-button__arrow:active .back-button__arrow-middle {
  transform: translateX(20px);
}

.back-button__arrow:active .back-button__arrow-bottom {
  transform: translate(12px, 12px);
}
</style>
