<template>
  <BackButton v-if="$route.path != '/'" />
  <router-view v-slot="{ Component }">
    <transition :name="$route.meta.transitionName" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
</template>

<script>
import BackButton from "./components/BackButton";
export default {
  name: "App",
  components: {
    BackButton,
  },
};
</script>

<style lang="css">
@font-face {
  font-family: "Josefin Sans";
  src: url(./fonts/JosefinSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Bagnard";
  src: url(./fonts/Bagnard.otf) format("opentype");
}

@font-face {
  font-family: "Atkinson Hyperlegible";
  src: url(./fonts/AtkinsonHyperlegible-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Atkinson Hyperlegible";
  src: url(./fonts/AtkinsonHyperlegible-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Atkinson Hyperlegible";
  src: url(./fonts/AtkinsonHyperlegible-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic;
}

body {
  margin: 0;
  background: rgb(5, 0, 10);
  color: white;
}

#app {
  font-family: "Atkinson Hyperlegible", Arial, sans-serif;
  font-size: 24px;
  color: white;
}

a {
  color: inherit;
}

a:active,
a:focus {
  outline: none;
}

/* Route Transitions */
.route-enter-active {
  transition: opacity 0.5s, filter 0.5s;
}

.route-leave-active {
  transition: opacity 0.5s, filter 0.25s;
}

.route-enter-from,
.route-leave-to {
  opacity: 0;
  filter: blur(50px);
}
</style>
